body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: sans-serif;
}

.str-video {
  background-color: #f1f4f6;
  color: #ffffff;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
}
.my-theme-overrides {
  font-family: sans-serif;
}

.my-theme-overrides .str-video__speaker-layout__wrapper {
  color: white;
}

.session-header {
  box-shadow: 0 0.46875rem 2.1875rem rgba(42, 46, 108, 0.03),
    0 0.9375rem 1.40625rem rgba(42, 46, 108, 0.03),
    0 0.25rem 0.53125rem rgba(42, 46, 108, 0.05),
    0 0.125rem 0.1875rem rgba(42, 46, 108, 0.03);
}
.session_header {
  display: "flex";
  justify-content: "space-between";
  align-items: "center";
  padding: "20px";
  background-color: "#ffffff";
  color: "white";
  position: "fixed";
  top: "0";
  left: "0";
  width: "100%";
  z-index: 1000;
  height: "55px";
}

.session_footer {
  height: 55px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #f8f9fa;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  z-index: 100;
  align-items: center;
  box-shadow: 0.3rem -0.46875rem 2.1875rem rgba(42, 46, 108, 0.02),
    0.3rem -0.9375rem 1.40625rem rgba(42, 46, 108, 0.02),
    0.3rem -0.25rem 0.53125rem rgba(42, 46, 108, 0.04),
    0.3rem -0.125rem 0.1875rem rgba(42, 46, 108, 0.02);
}

.session_stats {
  position: fixed;
  top: 52px;
  right: 0;
  width: 350px;
  height: 88%;
  background-color: black; /* Black background */
  color: white;
  padding: 20px;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  z-index: 101;
  transform: translateX(100%);
}

.session_stats.rd__sidebar--open {
  transform: translateX(0);
}

.participants-sidebar {
  position: fixed;
  top: 52px;
  right: 0;
  width: 350px;
  height: 88%;
  background-color: white; /* White background for chat */
  color: black; /* Set text color to black */
  padding: 20px;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  z-index: 101;
  transform: translateX(100%);
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2);
}

.participants-sidebar.rd__sidebar--open {
  transform: translateX(0);
}

.str-video__search-input__container {
  background-color: transparent;
  border: 0.5px solid #050505;
}

.str-video__search-input__container .str-video__search-input__icon {
  background-color: black;
}
.str-video__search-input__container .str-video__search-input__icon {
  color: black;
}

.chat-sidebar {
  position: fixed;
  top: 52px;
  right: 0;
  width: 400px;
  height: 88%;
  background-color: white; /* White background for chat */
  color: black; /* Set text color to black */
  padding: 5px;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  z-index: 101;
  transform: translateX(100%);
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2);
}

.chat-sidebar.rd__sidebar--open {
  transform: translateX(0);
}

.rd__header__elapsed {
  display: flex;
  justify-content: center;
  align-items: center;
  font-feature-settings: "tnum";
  font-variant: tabular-nums;
  background-color: #19232d;
  padding: 4px 12px;
  border-radius: 18px;
  font-size: 0.75rem;
}

.rd__header__elapsed-icon {
  margin-right: 4px;
  background-color: #00e2a1;
  height: 16px;
}
.rd__header__elapsed-time {
  font-weight: 400;
}

.call-btn {
  font-size: 20px;
  cursor: pointer;
}

.call-btn.active {
  background-color: green;
  color: #fff;
}

.call-btn.busy {
  background-color: red;
  color: #fff;
}

.str-video__participant-view {
  margin-top: -80px !important;
}

.str-video__speaker-layout--variant-right .str-video__speaker-layout__participants-bar-wrapper{
  overflow-y: visible;
}

.upper-canvas {
  height: 652px !important;
  /* display: none !important; */
}
.lower-canvas{
  /* display: none !important; */
}

.call-modal {
  text-align: center;
  padding: 20px;
}
/* .modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.caller-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.caller-name {
  font-size: 18px;
  font-weight: bold;
}
.group-name {
  font-size: 14px;
  color: gray;
}
.button-container {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}
.accept-btn, .reject-btn {
  padding: 8px 15px;
  font-size: 14px;
}

.sendbird-dropdown__menu{
  position: absolute !important;
  left: 250px !important;
}