// Sidebar Base

@use "sass:math";

.app-sidebar {
  width: $app-sidebar-width;
  display: flex;
  z-index: 11;
  overflow: hidden;
  min-width: $app-sidebar-width;
  position: relative;
  flex: 0 0 $app-sidebar-width;
  margin-top: -$app-header-height;
  padding-top: $app-header-height;
  transition: all .2s;

  .app-sidebar__inner {
    padding: 2px ($layout-spacer-x) $layout-spacer-x;
  }

  .scrollbar-container {
    z-index: 15;
    width: 100%;
    // background-color: red;
  }

  .app-sidebar-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.05;
    background-size: cover;
    z-index: 10;
  }

  .app-header__logo {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    z-index: 11;
  }

  &.sidebar-shadow {
    box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
  }
}

.app-sidebar__heading {
  text-transform: uppercase;
  font-size: $font-size-xs;
  margin: math.div($layout-spacer-x, 2) 0;
  font-weight: bold;
  // color: $primary;
  color: #ffff;
  white-space: nowrap;
  position: relative;
}

.sidebar-mobile-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #333;
  opacity: .6;
  left: 0;
  top: 0;
  z-index: 12;
}

// Sidebar Components

@import "modifiers/navmenu-vertical";

// Sidebar Modifiers

@import "themes/sidebar-light";
@import "themes/sidebar-dark";
//@import "themes/sidebar-inverted";


@import "modifiers/fixed-sidebar";
@import "modifiers/closed-sidebar";

//added by shilpa for side menu


.sidebar {
  color: white;
  padding-top: 15px;
}

.sidebar-heading {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
}

.menu-section {
  margin-bottom: 15px;
}

.menu-title {
  font-size: .96rem;
  font-weight: 600;
  margin-bottom: 5px;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.menu-list {
  list-style: none;
  padding-left: 5px;
}

.menu-item {
  padding: 5px 0;
}

.menu-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  padding: 8px 0;
}

.submenu {
  list-style: none;
  padding-left: 15px;
  position: relative;
   /* Transition Properties */
   max-height: 0;               /* Collapsed State */
   overflow: hidden;
   opacity: 0;
   transform: translateY(-5px); /* Subtle Slide-Up Effect */
 
   transition: all 0.1s ease-in-out;
}

/* Expanded State */
.submenu.open {
  max-height: 500px;           /* Arbitrary large number to allow expansion */
  opacity: 1;
  transform: translateY(0);     /* Smoothly slides into position */
}

.submenu-item a {
  text-decoration: none;
  color: #bdc3c7;
  display: inline-flex  ;
  width: 100%;
}
.submenu-item:hover {
  text-decoration: none;
  background: hsla(0, 0%, 100%, .102);
  color: #fffc;
  font-weight: 700;
  display: inline-flex  ;
  width: 100%;
}
.activeSidemenu {
  background: hsla(0, 0%, 100%, .102);
  color: #fffc;
  font-weight: 700;
}
.menu-icon, .submenu-icon {
  margin-right: 3px;
  font-size: 22px;
  width: 2rem;
}

.menu-items-wrap{
  display: flex;
  justify-content: left;
}
.submenu-item{
  border-radius: .375rem;
  color: #fff;
  display: block;
  height: 0 2.5rem ;
  line-height: 2.5rem;
  padding: 0 1.5rem 0 .5rem;
  position: relative;
  transition: transform .2s, height .3s, color .3s, background-color .3s;
  white-space: nowrap;
  margin:1px 0px;
}

.submenu:before {
  background: #e0f3ff;
  border-radius: 15px;
  content: "";
  height: 100%;
  left: .5rem;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 3px;
}

